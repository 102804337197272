@font-face {
  font-family: "Belanosima";
  src: local("Belanosima"),
    url("./fonts/Belanosima/Belanosima-SemiBold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "KleeOne";
  src: local("KleeOne"),
    url("./fonts/Klee_One/KleeOne-Regular.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}


@font-face {
  font-family: "Kaisei_Tokumin";
  src: local("Kaisei_Tokumin"),
    url("./fonts/Kaisei_Tokumin/KaiseiTokumin-Regular.ttf") format("truetype");
  font-weight: bold;
  font-display: block;
}

@font-face {
  font-family: "Noto_Serif_JP";
  src: local("Noto_Serif_JP"),
    url("./fonts/Noto_Serif_JP/NotoSerifJP-Regular.otf") format("opentype");
  font-weight: bold;
  font-display: block;
}

@font-face {
  font-family: "Shippori_Mincho";
  src: local("Shippori_Mincho"),
    url("./fonts/Shippori_Mincho/ShipporiMincho-Regular.ttf") format("truetype");
  font-weight: bold;
  font-display: block;
}

@font-face {
  font-family: "Tsukimi_Rounded";
  src: local("Tsukimi_Rounded"),
    url("./fonts/Tsukimi_Rounded/TsukimiRounded-Regular.ttf") format("truetype");
  font-weight: bold;
  font-display: block;
}

@font-face {
  font-family: "YokoMoji";
  src: local("YokoMoji"),
    url("./fonts/YokoMoji/yokomoji.otf") format("opentype");
  font-weight: bold;
  font-display: block;
}

@font-face {
  font-family: "LeftHanded";
  src: local("freefont_lefthanded"),
    url("./fonts/freefont_lefthanded/freefont_lefthanded.otf") format("opentype");
  font-weight: bold;
  font-display: block;
}

@font-face {
  font-family: "JiyunoTsubasa";
  src: local("JiyunoTsubasa"),
    url("./fonts/JiyunoTsubasa/JiyunoTsubasa.ttf") format("truetype");
  font-weight: bold;
  font-display: block;
}

@font-face {
  font-family: "YujiBoku";
  src: local("Yuji_Boku"),
    url("./fonts/Yuji_Boku/YujiBoku-Regular.ttf") format("truetype");
  font-weight: bold;
  font-display: block;
}




body {
  margin: 0px;
}

.in-game-score {
  font-size: 2.5em;
  user-select: none;
  width: auto;
}

.in-game-help-bar {
  display: flex;
  font-size: 1.5em;
  flex-wrap: wrap;
  gap: 0.5em;
  justify-content: center;
  align-items: center;
  text-align: center;
  user-select: none;
}

.in-game-exit-button {
  text-decoration: none; 
  color: #ff9a3c;
  font-size: 2.5em;
  margin-right: 14px;
  cursor: pointer;
  user-select: none;
  text-align: right;
}

.in-game-container {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.in-game-top-var {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 5px;
  height: 7%;
}

.in-game-game-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 93%;
}

.in-game-kana-character {
  font-size: 35vh;
  user-select: none;
  margin-top: 5vh;
  /* overflow: hidden; */
  height: 65%;
}

.in-game-kana-character {
  font-size: 35vh;
  user-select: none;
  margin-top: 5vh;
  /* overflow: hidden; */
  height: 65%;
}

.in-game-kana-character>p{
  margin: 0px;
}

.in-game-solution {
  position: absolute;
  top: calc(50% + 5vh);
  font-size: 15vh;
  user-select: none;
}

.in-game-romanji-character {
  font-size: 15vw;
}

#in-game-text-input-cursor-group {
  display: flex;
  justify-content: center;
  font-family: courier;
  font-size: 13vh;
  background: transparent;
  width: 90vw;
  color: #ff9a3c;
  padding: 5px;
  overflow: hidden;
  height: 14vh;
}

#in-game-text-input-cursor-group span {
  float: left;
  padding-left: 3px;
  white-space: pre;
  font-family: "KleeOne", "Comic Sans MS", "Comic Sans";
  align-self: center;
  user-select: none;
  padding-bottom: 10px;
}

#in-game-text-input-cursor {
  float: left;
  width: 3px;
  height: 11.5vh;
  background: #ff9a3c;
  margin-right: -4px;
  margin-left: -2px;
  align-self: center;
}

#in-game-text-input {
  width: 0;
  height: 0;
  opacity: 0;
}

.in-game-touch-answer-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 95%;
  gap: 10px;
  margin: 10px;
}

.in-game-touch-answer {
  width: 30%;
  font-size: 2em;
  text-align: center;
  border-radius: 30px;
  background-color: #00000027;
  user-select: none;
  cursor: pointer;
  display: grid;
  align-content: center;
}

.inGameUserGameScoreBackground {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  backdrop-filter: brightness(0.75) blur(25px);
  animation: fadeIn 0.3s linear 1 forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.inGameUserGameScoreWindow{
  border-style: solid;
  border-radius: 30px;
  padding: 3em;
  margin: 3em;
  gap: 35px;
  display: flex;
  flex-direction: column;
  box-shadow: 10px 10px 10px 0px #000000a8;
  background-color: #0000001c;
}

.inGameUserGameScoreWindow_header>h1{
  font-size: 13vh;
  text-align: center;
  margin: 0px;
  padding-bottom: 0px;
  user-select: none;
}
.inGameUserGameScoreWindow_header>h2{
  font-size: 7vh;
  text-align: center;
  margin: 0px;
  user-select: none;
}

.inGameUserGameScoreWindow_stats{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.inGameUserGameScoreWindow_stats_speed>p{
  text-align: center;
  font-size: 5vh;
  margin: 0px;
  user-select: none;
}
.inGameUserGameScoreWindow_stats_problematicKanas{
  display: flex;
  flex-direction: column;
  font-size: 4vh;
  text-align: center;
  gap: 10px;
}
.inGameUserGameScoreWindow_stats_problematicKanas>p{
  font-size: 5vh;
  margin: 0px;
  user-select: none;
}
.inGameUserGameScoreWindow_stats_problematicKanas>div{
  margin-bottom: 0px;
}
.inGameUserGameScoreWindow_stats_problematicKanas>div>p{
  margin: 0px;
  user-select: none;
}
.inGameUserGameScoreWindow_stats_problematicKanas>div{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}
.inGameUserGameScoreWindow_buttons{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;
  user-select: none;
}
.inGameUserGameScoreWindow_buttons button{
  background: transparent;
  border-style: solid;
  border-color: #ff9a3c;
  border-radius: 10px;
  padding: 10px;
  font-size: 2.5vh;
  color: #ff9a3c;
  font-weight: bold;
  cursor: pointer;
}
.inGameUserGameScoreWindow_buttons button:hover{
  background-color: #ff9a3c54;
}

.hidden-text-for-font-loading {
  position: absolute;
  visibility: hidden;
  left: -1px;
  top: -1px;
}

.hidden-element {
  position: absolute !important;
  visibility: hidden !important;
  left: -1px !important;
  top: -1px !important;
}

.font-Belanosima{
  font-family: Belanosima;
}
.font-KleeOne{
  font-family: KleeOne;
}
.font-Kaisei_Tokumin{
  font-family: Kaisei_Tokumin;
}
.font-Noto_Serif_JP{
  font-family: Noto_Serif_JP;
}
.font-Shippori_Mincho{
  font-family: Shippori_Mincho;
}
.font-Tsukimi_Rounded{
  font-family: Tsukimi_Rounded;
}
.font-YokoMoji{
  font-family: YokoMoji;
}
.font-LeftHanded{
  font-family: LeftHanded;
}
.font-JiyunoTsubasa{
  font-family: JiyunoTsubasa;
}
.font-YujiBoku{
  font-family: YujiBoku;
}
.font-forceDefault{
  font-family: Belanosima !important;
}

.animation-wrong1{
  animation-name: animation-wrong;
  animation-duration: 0.13s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes animation-wrong {
  25% {
    transform: translatex(-3px);
    color: #ff7d3c;
  }
  75% {
    transform: translatex(+3px);
    color: #ff7d3c;
  }
  100% {
    transform: translatex(0px);
    color: #ff7d3c;
  }
}

@media (max-width: 700px) {
  .inGameUserGameScoreWindow_buttons {
    flex-direction: column;
    align-content: center;
  }
}

@media (max-width: 750px) {
  .inGameUserGameScoreWindow{
    padding: 2em;
  }
  .inGameUserGameScoreWindow_header>h1{
    font-size: 10vh;
  }
  .inGameUserGameScoreWindow_header>h2{
    font-size: 5vh;
  }
  .inGameUserGameScoreWindow_stats_speed>p{
    font-size: 4vh;
  }
  .inGameUserGameScoreWindow_stats_problematicKanas>p{
    font-size: 4vh;
  }
  .inGameUserGameScoreWindow_stats_problematicKanas>div>p{
    font-size: 3.5vh;
  }
}

@media (min-width: 900px) {
  .in-game-touch-answer-group {
    width:60%;
    height: 35%;
  }
}